import { detailMenus } from '../views/calculate/lib'

export default [
  {
    path: 'home',
    name: 'home',
    component: () => import('../views/home/index')
  },
  {
    path: 'org',
    name: 'org',
    component: () => import('../views/org/index')
  },
  {
    path: 'worker',
    name: 'worker',
    component: () => import('../views/worker/index')
  },
  {
    path: 'project',
    name: 'project',
    component: () => import('../views/project/index')
  },
  {
    path: 'project/single',
    name: 'project.single',
    component: () => import('../views/project/single')
  },
  {
    path: 'unit',
    name: 'unit',
    component: () => import('../views/unit/router'),
    children: [
      {
        path: 'config',
        name: 'unit.config',
        component: () => import('../views/common/config'),
        props: { type: 'unit' }
      }
    ]
  },
  {
    path: 'worker',
    name: 'worker',
    component: () => import('../views/worker/router'),
    children: [
      {
        path: 'config',
        name: 'worker.config',
        component: () => import('../views/common/config'),
        props: { type: 'worker' }
      }
    ]
  },
  {
    path: 'calculate',
    name: 'calculate',
    component: () => import('../views/calculate/router'),
    children: [
      {
        path: 'index',
        name: 'calculate.index',
        component: () => import('../views/calculate/index')
      },
      {
        path: 'result',
        name: 'calculate.result',
        component: () => import('../views/calculate/result')
      },
      {
        path: 'details',
        name: 'calculate.details',
        component: () => import('../views/calculate/items/details'),
        children: [
          {
            path: 'menu',
            name: 'calculate.details.menu',
            component: () => import('../views/calculate/items/menu'),
          },
          ...detailMenus.filter(v => v.url).map(v => {
            return {
              path: v.url.replace('performance.calculate.details.', '').split('.').join('/'),
              name: v.url.replace('performance.', ''),
              component: () => import('../views/calculate/manage/index'),
              props: { page: v.key, title: v.label, mode: v.mode }
            }
          })
        ]
      }
    ]
  },
  {
    path: 'wages',
    name: 'wages',
    component: () => import('../views/wages/router'), 
    children: [
      {
        name: 'wages.worker.index',
        path: 'worker/index',
        component: () => import('../views/wages/worker/index'), 
      },
      {
        name: 'wages.manage.index',
        path: 'manage/index',
        component: () => import('../views/wages/manage/index'), 
      },
      {
        name: 'wages.manage.detail',
        path: 'manage/detail',
        component: () => import('../views/wages/manage/detail/index'), 
        children: [
          {
            name: 'wages.manage.detail.wait',
            path: 'wait',
            component: () => import('../views/wages/manage/detail/wait'), 
          },
          // {
          //   name: 'wages.manage.detail.check',
          //   path: 'check',
          //   component: () => import('../views/wages/manage/detail/check'), 
          // },
          {
            name: 'wages.manage.detail.end',
            path: 'end',
            component: () => import('../views/wages/manage/detail/end'), 
          }
        ]
      },
      {
        name: 'wages.config.default',
        path: 'config/default',
        component: () => import('../views/wages/config/default'), 
      },
    ]
  }
]